<template>
  <base-section id="k-d-a-tech-detail-point" class="padding">
    <div v-for="(item, id) in currentTechnology" :key="id">
      <base-section
        :class="id % 2 === 0 ? 'background-tech-list' : 'white'"
        :top-inner-shadow="id % 2 === 0"
        class="padding"
      >
        <v-container>
          <v-row>
            <v-col v-if="g_bHighestBr" lg="1" />
            <v-col v-else-if="g_bBaseBr" lg="1" />
            <v-col v-else-if="g_bHigherBr" lg="1" />
            <v-col
              cols="12"
              :lg="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
              :md="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
              style="flex-direction: column; display: flex; align-items: center"
            >
              <div class="img-container center">
                <img
                  :src="item.img"
                  :class="g_bLowestBr || g_bBaseBr ? 'top' : 'right'"
                  style="object-fit: contain"
                />
              </div>
            </v-col>
            <v-col v-if="g_bHighestBr" lg="1" />
            <v-col v-else-if="g_bBaseBr" lg="1" />
            <v-col v-else-if="g_bHigherBr" lg="1" />
            <v-col
              cols="12"
              :lg="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
              :md="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
              :class="`px-${g_bLowerBr || g_bBaseBr ? 4 : 0}`"
              style="
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div>
                <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold`">
                  {{ item.strTitle }}
                </div>
                <component
                  :is="`p`"
                  :class="`kda-ts-${g_bLowerBr ? 12 : 18}pt nunito wt-regular mt-4 mt-md-4`"
                  v-html="item.htmlDesc"
                />
              </div>
            </v-col>
            <v-col v-if="g_bHighestBr" lg="1" />
            <v-col v-else-if="g_bBaseBr" lg="1" />
            <v-col v-else-if="g_bHigherBr" lg="1" />
          </v-row>
        </v-container>
      </base-section>
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDATechDetailPoint',

  mixins: [BaseBreakpoint],

  data: () => ({
    strBack: 'Kembali ke Teknologi'
  }),

  computed: {
    arrTechnology() {
      return {
        'unreal-engine': [
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.ask1'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.explanation1'),
            img: require('@/assets/photos/tech-detail-1.png')
          },
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.ask2'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.explanation2'),
            img: require('@/assets/photos/tech-detail-2.png')
          }
        ],
        'my-sql': [
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.ask1'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.explanation1'),
            img: require('@/assets/photos/tech-detail-3.png')
          },
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.ask2'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.explanation2'),
            img: require('@/assets/photos/tech-detail-4.png')
          }
        ],
        'django-python': [
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.ask1'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.explanation1'),
            img: require('@/assets/photos/tech-detail-5.png')
          },
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.ask2'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.explanation2'),
            img: require('@/assets/photos/tech-detail-6.png')
          }
        ],
        unity: [
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.unity.ask1'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.unity.explanation1'),
            img: require('@/assets/photos/tech-detail-1.png')
          },
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.unity.ask2'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.unity.explanation2'),
            img: require('@/assets/photos/tech-detail-2.png')
          }
        ],
        'keda-engine': [
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.ask1'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.explanation1'),
            img: require('@/assets/photos/tech-detail-3.png')
          },
          {
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.ask2'),
            htmlDesc: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.explanation2'),
            img: require('@/assets/photos/tech-detail-4.png')
          }
        ]
      };
    },

    currentTechnology() {
      const currentTechnology = Object.keys(this.arrTechnology).filter(
        (item) => item === this.$route.params.strTechnology
      );

      return this.arrTechnology[currentTechnology];
    }
  },

  methods: {
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.padding {
  padding: 0px !important;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 100%;
      position: relative;
      margin-top: 30px;
    }
  }
}
</style>
